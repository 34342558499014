// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyBWVcvq-u9a2scKaZ6J2XDaS8MudrthMEg",
  authDomain: "yuze--bengal-heritage.firebaseapp.com",
  projectId: "yuze--bengal-heritage",
  storageBucket: "yuze--bengal-heritage.appspot.com",
  messagingSenderId: "147561862741",
  appId: "1:147561862741:web:646310c5e292c9e68ee6dc",
  measurementId: "G-HZ90N283ZC"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyC-DsWeMrcWpnLHcWdSXKuJ2vtJbA5dLGs",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:3c2cbbff655c8547e8dec1",
  measurementId: "G-KLRDX1529N"
};

export { firebaseCompanyConfig, firebaseBackOfficeConfig }
